.ufo-ic {
	position: absolute;
	right: 0;
}

.ac-details-wrapper {
	background-color: var(--white-bg);
	padding: 30px 0 30px 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
	padding-right: 26%;
	overflow: hidden;
}

.p-bg.position-absolute {
	right: 0;
	bottom: 0;
	height: 100%;
	object-fit: cover;
	z-index: 0;
	max-width: 600px;
}

.ac-right {
	background-color: var(--primaryColor3);
	padding: 15px 30px;
	border-radius: 30px;
}

.ac-left,
.ac-right {
	position: relative;
	width: 80%;
	z-index: 1;
}

.ac-left .input-inside input {
	width: 60%;
}

.tg {
	border-bottom: 1px solid #293a5a;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-gap: 30px;
	padding: 12px 0;
	color: var(--textColor);
}

.cls {
	border-bottom: 1px solid #293a5a;
	font-size: 20px;
	text-transform: uppercase;
	padding: 10px 0;
	color: var(--textColor);
}

.blc-chunk h6 {
	font-size: 15px;
	color: var(--textColor);
	margin-bottom: 2px;
}

.blc-chunk p {
	font-size: 24px;
	line-height: 1.1;
}

.blc-wrap .blc-chunk:not(:last-child) {
	margin-bottom: 5px;
}

.progress-bar {
	background: linear-gradient(269.93deg, #4c6fff 7.72%, #1adcab 102.59%);
	border-radius: 30px;
}

.progress {
	height: 12px;
	background-color: var(--progressBarBg);
	border-radius: 30px;
	margin-bottom: 5px;
}

.progress-values p {
	font-size: 15px;
}

.blc-chunk.style-2 {
	padding: 12px 0;
}

.heart-reverse {
	width: 100%;
	margin: auto;
}

.font-bebas-bold.pulker {
	font-size: 40px;
	margin-bottom: 10px;
	line-height: 1.1;
}

.status {
	display: flex;
	margin-right: 40px;
	align-items: center;
	padding: 5px 40px;
	border-radius: 8px;
}

.status.pink {
	color: var(--textColor);
	background-color: var(--btn-green-bg);
}

.ac-left p {

	font-size: 16px;
	line-height: 1.4;
	margin-bottom: 15px;
	opacity: 0.9;
}

.statuses {
	margin-bottom: 12px;
	display: flex;
	margin: 30px 0;
}

.bsBtn-info.theme-bg {
	background-color: var(--theme-color-one);
}

.bsBtn-info.theme-bg:hover {
	background-color: var(--black);
}

.bx-shadow {
	box-shadow: 0 10px 15px var(--btn-green-bg);
}

.tbl-title {
	background-color: var(--primaryColor3);
	font-size: 22px;

	padding: 10px 15px;
	border-radius: 12px 12px 0 0;
	margin: 0;
	color: var(--textColor);
	border-bottom: 1px solid #293a5a;
}

.tbl-rw {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 15px;
	/* background-color: var(--primaryColor3); */
	width: 70%;
	padding: 10px 15px;
}

.tbl-td {
	color: var(--textColor);
	font-size: 16px;
}

.project-info-g-wrapper {
	display: flex;
	flex-direction: column;
}

.info-rg-img {
	text-align: center;
}

.nav-tabs {
	justify-content: left;
	margin-bottom: 30px;
	border: 0;
}

.project-info-area {
	padding-top: 40px;
	padding-bottom: 40px;
}

.info-table {
	border-radius: 12px;
	overflow: hidden;
	/* background: var(--primaryColor3); */
}

.tbl-scroll-wrapper {
	border-radius: 12px;
}

.launpad-box {
	cursor: pointer;
}

/* Media Query */
@media screen and (max-width: 1365px) {
	.ac-details-wrapper {
		grid-template-columns: 1fr 1.8fr;
		padding: 30px;
	}

	.project-info-g-wrapper {
		grid-template-columns: 1.3fr 1fr;
	}

	.ac-right {
		padding: 20px 30px;
		border-radius: 20px;
	}

	.ac-details-wrapper {
		padding-right: 30px;
	}
}

@media screen and (max-width: 991px) {
	.project-info-g-wrapper {
		display: block;
	}

	.info-table {
		min-width: 600px;
	}

	.project-info-right {
		margin-top: 45px;
	}

	.project-info-area {
		padding-top: 55px;
		padding-bottom: 0;
	}

	.ac-details-wrapper {
		grid-template-columns: 1fr;
	}

	.p-bg.position-absolute {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.info-table {
		min-width: 300px;
	}

	.ac-details-wrapper {
		padding: 15px;
		padding-top: 25px;
	}

	.font-bebas-bold.pulker {
		font-size: 34px;
	}

	.heart-reverse {
		width: 100%;
	}

	.ac-left p {
		font-size: 16px;
	}

	.ac-left p br {
		display: none;
	}

	.ac-right {
		padding: 10px 15px;
		border-radius: 12px;
	}

	.tg {
		grid-template-columns: 1fr;
		grid-gap: 15px;
	}

	.blc-chunk p {
		font-size: 20px;
	}

	.progress-values p {
		font-size: 12px;
	}

	.nav-tabs button.nav-link {
		margin-bottom: 10px;
	}

	.nav-tabs {
		margin-bottom: 20px;
	}

	.project-info-area {
		padding-top: 40px;
	}

	.tbl-title {
		font-size: 18px;
	}
}

.ido-card-banner-image {
	background: #212121;
	border-radius: 15px;
	margin-bottom: 25px;
	min-height: 250px;
	box-shadow: var(--bsShadowOut);
}

.ido-card-banner-image>img {
	max-width: 300px;
	max-height: 100px;
	min-width: 100PX;
}

.ido-card p {
	color: var(--textColor);
}

.ido-card span {
	color: var(--textColor) !important;
}

.ido-card h3 {
	font-size: 22px;
	color: var(--textColor);
}

.ido-date img {
	margin-right: 10px;
}

.date-section {
	font-size: 13px;
	opacity: .5;
	color: black;
}

.ido-paragraph {
	font-size: 14px;
	opacity: 0.6;
}

.network-flex p {
	text-align: left;
	font-size: 15px;
}

.bsFoot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid rgb(255 255 255 / 10%);
	padding-top: 15px;
	position: relative;
}

.bsFoot:before {
	width: 100%;
	height: 2px;
	background-color: rgb(0 0 0 / 20%);
	position: absolute;
	top: -3px;
	right: 0;
	left: 0;
	content: "";
}

.launchpad-stats {
	display: flex;
	align-items: center;
}

.launchpad-ic {
	padding: 22px;
	border-radius: 18px;
	margin-right: 18px;
	box-shadow: var(--bsShadowOB);
	-webkit-box-shadow: var(--bsShadowOB);
	background: linear-gradient(318deg, #0b0c0c, #262727);
}

.bold-digit {
	color: var(--textColor);
	margin-top: 8px;
	margin-bottom: 0;
}

.rocket-image {
	width: 22%;
	position: absolute;
	right: -1%;
	bottom: 24%;
}

.stack-amount-input label {
	color: var(--textColor);
}

.opacity-text {
	opacity: 0.6;
}

/* .date-section {margin-left: 10px;} */